import React, { forwardRef, ReactNode, ComponentType, SVGProps } from "react";
import clsx from "clsx";

import { Spinner } from "../Spinner";
import type { TButton } from "./Button.types";

import "./Styles.css";

export const Button = forwardRef<HTMLButtonElement, TButton>(
  (
    {
      variant = "primary",
      startIcon,
      endIcon,
      children,
      size = "small",
      className,
      disabled,
      loading,
      ...props
    },
    ref
  ) => {
    const isOnlyIcon = () => {
      return loading || (!children && (!!startIcon || !!endIcon));
    };

    const renderIcon = (
      icon: ComponentType<SVGProps<SVGElement>> | ReactNode
    ) => {
      if (React.isValidElement(icon)) {
        return icon;
      }
      if (typeof icon === "function") {
        const IconComponent = icon as React.ElementType;
        return <IconComponent />;
      }
      return icon;
    };

    return (
      <button
        {...props}
        disabled={loading || disabled}
        ref={ref}
        className={clsx(
          "button-DS",
          variant,
          size,
          isOnlyIcon() && "only-icon",
          className
        )}
      >
        {loading ? (
          <Spinner
            size={size === "large" || size === "xlarge" ? "medium" : size}
            variant={"secondary"}
          />
        ) : (
          <>
            {renderIcon(startIcon)}
            {children}
            {renderIcon(endIcon)}
          </>
        )}
      </button>
    );
  }
);
