import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";

import { TabsSectionProps, TabTagProps, TabItemProps } from "./Tabs.types";

import "./Styles.css";

const sizeConfig = {
  lg: {
    textSize: "md",
    gap: "12px",
  },
  md: {
    textSize: "sm",
    gap: "10px",
  },
  sm: {
    textSize: "xs",
    gap: "8px",
  },
} as const;

const TabTag: React.FC<TabTagProps> = ({ tag, disabled, size }) => (
  <span className={clsx("tag-DS", `tag-${size}-DS`, { "tag-disabled-DS": disabled })}>
    {tag}
  </span>
);

const TabItem: React.FC<TabItemProps> = React.memo(
  ({
    tab,
    index,
    onClick,
    itemClassName,
    itemStyle,
    setRef,
    isSelected,
    size = "md",
  }) => (
    <button
      key={tab?.key || `tab-${tab.label}-${index}-DS`}
      onClick={onClick}
      id={tab.id}
      className={clsx(
        "tabs-item-DS",
        `tabs-item-${size}-DS`,
        itemClassName,
        tab.className,
        isSelected && "selected-DS",
        {
          "tabs-item-disabled-DS": tab.disabled,
        }
      )}
      ref={setRef}
      style={{
        gap: sizeConfig[size].gap,
        ...itemStyle,
      }}
      role="tab"
      aria-selected={isSelected}
      aria-controls={`panel-${tab.id}`}
      aria-disabled={tab.disabled}
      tabIndex={isSelected ? 0 : -1}
    >
      {tab.icon} {tab.label}
      {tab.tag && (
        <TabTag tag={tab.tag} disabled={tab.disabled ?? false} size={size} />
      )}
    </button>
  )
);

export const Tabs: React.FC<TabsSectionProps> = ({
  selectedTab,
  setSelectedTab,
  tabs,
  containerClassName,
  containerStyle,
  itemClassName,
  itemStyle,
  size = "md",
  showShadow = true,
}) => {
  const [underlineStyle, setUnderlineStyle] = useState<React.CSSProperties>({});
  const tabsRef = useRef(new Map<number, HTMLButtonElement>());

  useEffect(() => {
    const currentTab = tabsRef.current.get(selectedTab);
    if (currentTab) {
      const updateUnderline = () => {
        setUnderlineStyle({
          width: currentTab.offsetWidth,
          left: currentTab.offsetLeft,
        });
      };
      const resizeObserver = new ResizeObserver(updateUnderline);
      resizeObserver.observe(currentTab);
      updateUnderline();
      return () => resizeObserver.disconnect();
    }
  }, [selectedTab, size]);

  const isDisabled = tabs[selectedTab]?.disabled;

  return (
    <nav
      className={clsx("tabs-DS", containerClassName, {
        "tabs-no-shadow-DS": !showShadow,
      })}
      style={containerStyle}
      role="tablist"
    >
      {tabs.map((tab, index) => (
        <TabItem
          tab={tab}
          index={index}
          onClick={() => setSelectedTab(index)}
          itemClassName={itemClassName}
          itemStyle={itemStyle}
          setRef={(el) =>
            el ? tabsRef.current.set(index, el) : tabsRef.current.delete(index)
          }
          isSelected={selectedTab === index}
          size={size}
        />
      ))}
      <span
        className={clsx("underline-DS", { "underline-disabled-DS": isDisabled })}
        style={underlineStyle}
      />
    </nav>
  );
};
