import React, { forwardRef } from "react";
import type { TSpinner } from "./Spinner.types";
import "./Styles.css";
import clsx from "clsx";

export const Spinner = forwardRef<HTMLDivElement, TSpinner>(
  ({ variant = "primary", size = "small", className, ...props }, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={clsx("spinner-DS", variant, size, className)}
      />
    );
  }
);
